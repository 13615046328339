 <!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
    <a-row :gutter="24"> 
        <a-col :span="6">
            <a-card size="small" title="وضعیت">
                وضعیت
                
                <template slot="actions" class="ant-card-actions">
                    <a-icon key="setting" type="setting" />
                    <a-icon key="edit" type="edit" />
                    <a-icon key="ellipsis" type="ellipsis" />
                </template>

            </a-card>
        </a-col>
        <a-col :span="18" >
            <a-card title="ویرایش">
                <a-tabs >
                    <a-tab-pane key="1" tab="کلید ها">
                    Content of tab 1
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="باگ ها">
                    Content of tab 2
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="ویرایش">

                        <a-form  :form="form">
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="نام پروژه"
                            >
                                <a-input
                                v-decorator="[
                                    'name_fa',
                                    {
                                        rules: [{ required: true, message: 'لطفا نام پروژه خود را به فارسی بنویسید' }]
                                    },
                                    
                                ]"

                                placeholder="بنویسید"
                                />
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="نام پروژه به انگلیسی"
                            >
                                <a-input
                                v-decorator="[
                                    'name',
                                    {
                                        rules: [{ required: true, message: 'لطفا نام پروژه خود را به انگلیسی بنویسید' }]
                                    },
                                    
                                ]"

                                placeholder="بنویسید"
                                />
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="تکنولوژی های مورد استفاده در محیط وب ">
                                <b>انتخاب فرمورک سمت کاربر (Front-End)</b>
                                <a-radio-group>
                                    <a-radio-button value="semantic">
                                    Semantic UI/jQuery
                                    </a-radio-button>
                                    <a-radio-button value="boostrap">
                                    Bootstrap/jQuery
                                    </a-radio-button>
                                    <a-radio-button value="vuejs">
                                        VueJS
                                    </a-radio-button>
                                    <a-radio-button value="react">
                                        ReactJS
                                    </a-radio-button>
                                    <a-radio-button value="private">
                                        طراحی اختصاصی
                                    </a-radio-button>
                                </a-radio-group>
                                <b>انتخاب فرمورک سمت سرور (Back-End)</b> -
                                <a-alert message=" در حال حاظر تمام پروژه ها با زبان PHP طراحی میشوند!" type="info" show-icon />
                                <a-radio-group>
                                    <a-radio-button value="codeigniter">
                                        CodeIgniter
                                    </a-radio-button>
                                    <a-radio-button value="laravel">
                                        Laravel
                                    </a-radio-button>
                                    <a-radio-button value="symfony">
                                        Symfony
                                    </a-radio-button>
                                    <a-radio-button value="private">
                                        طراحی اختصاصی
                                    </a-radio-button>
                                </a-radio-group>
                                <a-divider/>
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="پلتفرم های مورد پشتیبانی "
                            >
                                <a-switch value="web" @change="selectPlatform" default-checked /> Web (وبسایت)
                                <a-divider/>
                                <a-switch value="tgbot" @change="selectPlatform" /> Telegram Bot (ربات تلگرام)
                                <a-divider/>
                                <a-switch value="android" @change="selectPlatform" /> Android (نرم افزار اندروید)
                                <a-divider/>
                                <a-switch  @change="selectPlatform" disabled /> iOS (نرم افزار گوشی های اپل)
                                <a-divider/>
                                <a-switch value="windows"  @change="selectPlatform" /> Windows (نرم افزار ویندوز)
                                <a-divider/>
                                <a-switch value="linux"  @change="selectPlatform" /> Linux (نرم افزار لینوکس)
                                <a-divider/>
                                <a-switch  @change="selectPlatform" disabled /> MacOS (نرم افزار سیستم های مک)
                                <a-divider/>
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="نام و نام خانوادگی"
                            >
                                <a-input
                                v-decorator="[
                                    'fullname',
                                    {
                                    rules: [{ required: true, message: 'لطفا نام و نام خانوادگی کاربر را وارد کنید' }],
                                    initialValue: modifyUser.fullname
                                    },
                                    
                                ]"

                                placeholder="بنویسید"
                                />
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="نام کاربری"
                            >
                                <a-input
                                v-decorator="[
                                    'username',
                                    {
                                    rules: [{ required: true, message: 'Please input your name' }],
                                    initialValue: modifyUser.username
                                    },
                                ]"
                                placeholder="Please input your name"
                                class="ltr"
                                />
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="آدرس ایمیل"
                            >
                                <a-input
                                v-decorator="[
                                    'email',
                                    {
                                    rules: [{ required: true, message: 'لطفا آدرس ایمیل کاربر را وارد کنید' }],
                                    initialValue: modifyUser.email
                                    },
                                ]"
                                class="ltr"
                                placeholder="بنویسید"
                                />
                            </a-form-item>
                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="شماره تلفن"
                            >
                                <a-input
                                v-decorator="[
                                    'phone_number',
                                    {
                                    rules: [{ required: true, message: 'Please input your name' }],
                                    initialValue: modifyUser.phone_number
                                    },
                                ]"
                                placeholder="919123456"
                                class="ltr"
                                />
                            </a-form-item>

                            <a-form-item
                                :label-col="formItemLayout.labelCol"
                                :wrapper-col="formItemLayout.wrapperCol"
                                label="گروه کاربری"
                            >

                                <a-select
                                mode="multiple"
                                placeholder="User Groups"
                                :value="selectedRoles"
                                style="width: 100%"
                                @change="modifyUserGroup"
                                >
                                <a-select-option v-for="userGroup in userGroups" :key="userGroup" selected :value="userGroup">
                                    {{ userGroup }}
                                </a-select-option>
                                </a-select>

                            </a-form-item> 
                        </a-form>
                    </a-tab-pane>
                </a-tabs>
            </a-card>
       </a-col>

  </a-row>
</template>

<style scoped lang="scss">
</style>
<script>
	import Vue from 'vue'
 const data = [
];
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

	import { userService } from '../../../services/user.service';
	import { adminService } from '../../../services/admin.service';
	export default ({
		components: { 
		},
    created(){
      // form layout
      this.$form.layout = 'horizontal'
      
      // set admin user
      this.user = Vue.prototype.$me;

      // load user list
      adminService.getProjects().then(response => {    
        if (response.status == 200) {
          return response.json();            
        } else {
          alert('asd');
        }
      }).then((response) => {

        this.data = response.data.projects
        //var that = this;
    //
        //this.data.forEach(function(user,key){ 
        //  
        //});
        //response.data.groups.forEach(function(user,key){ 
        //  that.userGroups[key] = user.name;
        //});
      }).catch((e) => {
        console.log(e);
      });
    },
		data() {
			return {
                selectedRoles: [],
                checkNick: false,
                formItemLayout,
                formTailLayout,
                form: this.$form.createForm(this, { 
                name: 'dynamic_rule' 
                }),
                modifyUser: {},
                modifyModel: null,
                user: null, 
				data,
				userGroups: {},
				filteredTags: [],
				tagsFilterDropdownVisible: false,
			}
		},
		methods: { 
            modifyUserGroup(selectedRoles) {
                this.selectedRoles = selectedRoles
            },
            selectPlatform(){}
		}
	})

</script>
 